import React from "react";
import { useState } from "react";
import validator from "validator";
import styles from "./ForgotUsername.module.css";
import Button from "../../Button/Button";
import axios from "axios";
import logo from "../../../assets/logo.png";
import Config from "../../../config/Config";
import { ReactComponent as Flint } from "../../../assets/flint.svg";
import ccVault from "../../../assets/ccvault.png";
import Gero from "../../../assets/gero.png";
import { ReactComponent as Nami } from "../../../assets/nami.svg";
import nuFi from "../../../assets/nufi.png";
import Modal from "react-modal";
import "../../Modal/Modal.css";
import Card from "../../Card/Card";
import ReactModal from "../../Modal/Modal";

const ForgotUsername = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [wallet_name, setWalletName] = useState();
  const onChange = async (ev) => {
    setWalletName(
      localStorage.setItem(
        "wallet_name",
        ev.target[Object.keys(ev.target)[1]].id
      )
    );
    setInterval(() => {
      localStorage.removeItem("wallet_name");
    }, 1000);
    setData();
    setIsOpen(false);
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function toHex(str) {
    var result = "";
    for (var i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return result;
  }
  function checkInputs(inputs) {
    var filled = true;
    inputs.forEach(function (input) {
      if (input.value === "") {
        filled = false;
      }
    });
    return filled;
  }
  const inputs = document.querySelectorAll("input");
  const button = document.querySelector("button");
  inputs.forEach(function (input) {
    input.addEventListener("keyup", function () {
      if (checkInputs(inputs)) {
        button.disabled = false;
      } else {
        button.disabled = true;
      }
    });
  });
  const [showRecoverButton, setShowRecoverButton] = useState(true);
  const PORT = 3080;
  const URL = Config.url("/v1/ForgotUsername");
  const api = axios.create({
    baseURL: URL,
  });
  const returnSignature = async () => {
    let cc = await window.cardano[localStorage.getItem("wallet_name")].enable();
    if (cc) {
      const address = await cc.getChangeAddress();
      const currentDate = new Date();
      const signature = await cc.signData(
        address,
        toHex(JSON.stringify({ date: currentDate, playbitke: "Bitke game" }))
      );
      return {
        date: currentDate,
        signature: signature,
        address: address,
      };
    } else {
      setErrorMessage(
        "not is possible get your nami address! please, try again!"
      );
      setInterval(() => {
        setErrorMessage("");
      }, 4000);
    }
  };
  const setData = async () => {
    api
      .post(URL, await returnSignature())
      .then((response) => {
        setSuccessMsg("Your Username is: " + response.data.username);
        setErrorMessage("");
        setShowRecoverButton(false);
      })
      .catch((error) => {
        if (Object.keys(error.response.data).includes("error")) {
          setSuccessMsg("");
          setErrorMessage(error.response.data.error);
        }
      });
  };
  return (
    <div className={styles.center}>
      <img src={logo} alt="Logo bitke" />
      <h1>Forgot Username</h1>
      <div className="pass-input">
        <label htmlFor="password" className={styles.pass}>
          Please click on button bellow to recover username
        </label>
        <span className={styles.msg_error}>{errorMessage}</span>
        <span className={styles.msg_success}>{successMsg}</span>
      </div>
      <Button value="Enter" onClick={openModal} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Login with"
        className="Modal"
        overlayClassName="Overlay"
      >
        <center>
          <div className="card-container">
            <h1>Connect your cardano wallet</h1>
            <Card>
              <div className="wallet-container">
                <Flint />
                <p onClick={onChange} id="flint">
                  Flint Wallet
                </p>
              </div>
            </Card>
            <Card>
              <div className="wallet-container">
                <Nami className="nami" />{" "}
                <p id="nami" onClick={onChange}>
                  Nami Wallet
                </p>
              </div>
            </Card>
            <Card>
              <div className="wallet-container">
                <img src={ccVault} className="ccvault" />
                <p onClick={onChange} id="ccvault">
                  CCVault Wallet
                </p>
              </div>
            </Card>
            <Card>
              <div className="wallet-container">
                <img src={Gero} className="gero" />
                <p onClick={onChange} id="gerowallet">
                  Gero Wallet
                </p>
              </div>
            </Card>
            <Card>
              <div className="wallet-container">
                <img src={nuFi} className="nufi" />
                <p id="nufi" onClick={onChange}>
                  nuFi Wallet
                </p>
              </div>
            </Card>
          </div>
        </center>
      </Modal>
    </div>
  );
};
export default ForgotUsername;
