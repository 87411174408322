import './App.css';
import Modal from 'react-modal';
import Rotas from './Routes';
import {useState} from 'react'

Modal.setAppElement("#root")
function App() {
  return (
    <div>
      <Rotas />
    </div>
  );
}

export default App;
