import React from "react";
import { Navigate, Outlet } from "react-router-dom";
function isTokenExpired(token) {
  const expiry = JSON.parse(atob(token.split(".")[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
}
export const PrivateRoute = () => {
  let auth = false;
  const getItemStorage = localStorage.getItem("token");
  if (getItemStorage) {
    if (!isTokenExpired(getItemStorage)) {
      auth = true;
    } else {
      localStorage.clear(getItemStorage);
    }
  }
  return auth ? <Outlet /> : <Navigate to="/login" />;
};
