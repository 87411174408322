import styles from "./Button.module.css";
const Button = (props) => {
  const type = props.type ? props.type: "button"
  return (
    <button type="submit" onClick={props.onClick} styles={styles.button}>
      {props.value}
    </button>
  );
};
export default Button;
