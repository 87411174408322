import React from "react";
import { useState } from "react";
import validator from "validator";
import styles from "./EditUser.module.css";
import Button from "../../Button/Button";
import axios from "axios";
import logo from "../../../assets/logo.png";
import Config from "../../../config/Config";
import { ReactComponent as Flint } from "../../../assets/flint.svg";
import ccVault from "../../../assets/ccvault.png";
import Gero from "../../../assets/gero.png";
import { ReactComponent as Nami } from "../../../assets/nami.svg";
import nuFi from "../../../assets/nufi.png";
import Modal from "react-modal";
import "../../Modal/Modal.css";
import Card from "../../Card/Card";

const SignUp = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [wallet_name, setWalletName] = useState();
  const logout = () => {
    const getItemStorage = localStorage.getItem("token");
    localStorage.clear(getItemStorage);
    window.location.reload();
  };
  const onChange = async (ev) => {
    setWalletName(
      localStorage.setItem(
        "wallet_name",
        ev.target[Object.keys(ev.target)[1]].id
      )
    );
    setIsOpen(false);
    setData();
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function toHex(str) {
    var result = "";
    for (var i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return result;
  }
  function checkInputs(inputs) {
    var filled = true;
    inputs.forEach(function (input) {
      if (input.value === "") {
        filled = false;
      }
    });
    return filled;
  }
  const inputs = document.querySelectorAll("input");
  const button = document.querySelector("button");
  inputs.forEach(function (input) {
    input.addEventListener("keyup", function () {
      if (checkInputs(inputs)) {
        button.disabled = false;
      } else {
        button.disabled = true;
      }
    });
  });
  const validate = async (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage("Password accepted");
    } else {
      setErrorMessage(
        "your password must be at least 8 characters long, one special character, one uppercase and one lowercase letter"
      );
    }
  };
  const [newPassword, setnewPassword] = useState();
  const [lastPassword, setLastPassword] = useState();
  const PORT = 3080;
  const URL = Config.url("/v1/Update");
  //const URL = "http://localhost:"+PORT+"/v1/Update";
  const api = axios.create({
    baseURL: URL,
  });
  const returnSignature = async () => {
    let cc = await window.cardano[localStorage.getItem("wallet_name")].enable();
    if (cc) {
      const address = await cc.getChangeAddress();
      const signature = await cc.signData(
        address,
        toHex(
          JSON.stringify({
            last_password: lastPassword,
            new_password: newPassword,
          })
        )
      );
      return {
        last_password: lastPassword,
        new_password: newPassword,
        signature: signature,
        address: address,
      };
    } else {
      setErrorMessage(
        "not is possible get your nami address! please, try again!"
      );
      setInterval(() => {
        setErrorMessage("");
      }, 4000);
    }
  };
  const setData = async () => {
    api.interceptors.request.use((config) => {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
      return config;
    });
    api
      .put(URL, await returnSignature())
      .then((response) => {
        setSuccessMsg("User saved!");
        setInterval(() => {
          setSuccessMsg("");
          window.location.assign(Config.origin("/Login"));
        }, 2000);
      })
      .catch((error) => {
        if (Object.keys(error.response.data).includes("error")) {
          if (error.response.data.error === "Wrong password.") {
            setErrorMessage("Wrong password.");
            setInterval(() => {
              setErrorMessage("");
            }, 4000);
          } else if (
            error.response.data.error == "Please send the correct input."
          ) {
            setErrorMessage("Please, send the correct input.");
            setInterval(() => {
              setErrorMessage("");
            }, 4000);
          } else if (error.response.data.error === "Good try...") {
            window.location.href("https://www.youtube.com/watch?v=dQw4w9WgXcQ");
          }
        } else {
          setErrorMessage("Error connecting to server");
          setInterval(() => {
            setErrorMessage("");
          }, 4000);
        }
      });
  };
  return (
    <div className={styles.center}>
      <img src={logo} alt="Logo bitke" />
      <h1>Edit password</h1>
      <form className={styles.form} onSubmit={setData}>
        <div className="last_pass-input">
          <label htmlFor="last_password" className={styles.nickname}>
            Last password:
          </label>
          <input
            type="password"
            className="last_pass-input"
            id="last_password"
            placeholder="Password@22"
            onChange={(e) => setLastPassword(e.target.value)}
          />
        </div>
        <div className="pass-input">
          <label htmlFor="password" className={styles.pass}>
            New password:
          </label>
          <input
            type="password"
            className="pass-input"
            id="password"
            placeholder="NewPassword@22"
            onChange={(e) => {
              validate(e.target.value);
              setnewPassword(e.target.value);
            }}
          />
          <span className={styles.msg_error}>{errorMessage}</span>
          <span className={styles.msg_success}>{successMsg}</span>
        </div>
        <Button value="Enter" onClick={openModal} />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Login with"
          className="Modal"
          overlayClassName="Overlay"
        >
          <center>
            <div className="card-container">
              <h1>Connect your cardano wallet</h1>
              <Card>
                <div className="wallet-container">
                  <Flint />
                  <p onClick={onChange} id="flint">
                    Flint Wallet
                  </p>
                </div>
              </Card>
              <Card>
                <div className="wallet-container">
                  <Nami className="nami" />{" "}
                  <p id="nami" onClick={onChange}>
                    Nami Wallet
                  </p>
                </div>
              </Card>
              <Card>
                <div className="wallet-container">
                  <img src={ccVault} className="ccvault" />
                  <p onClick={onChange} id="ccvault">
                    CCVault Wallet
                  </p>
                </div>
              </Card>
              <Card>
                <div className="wallet-container">
                  <img src={Gero} className="gero" />
                  <p onClick={onChange} id="gerowallet">
                    Gero Wallet
                  </p>
                </div>
              </Card>
              <Card>
                <div className="wallet-container">
                  <img src={nuFi} className="nufi" />
                  <p id="nufi" onClick={onChange}>
                    nuFi Wallet
                  </p>
                </div>
              </Card>
            </div>
          </center>
        </Modal>
      </form>
      <button onClick={logout}>Logout </button>
    </div>
  );
};
export default SignUp;
