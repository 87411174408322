

function url(path){
  return "https://api.playbitke.com"+path
  //return "http://localhost:3080"+path
}

function origin(path){
  return window.location.origin+path;
}
export default {
  url,
  origin
};